// src/pages/Products.js

import React from "react";
import Layout from "../components/Layout/Layout";
import ProductCard from "../components/services/ProductCard"; // ProductCard component for products
import ServiceCard from "../components/services/ServiceCard"; // ServiceCard for partner products

const productData = [
  {
    id: "Service&ProductExchange",
    title: "Service & Product Exchange",
    description:
      "Discover a unique platform where products and services come together seamlessly. Whether you're shopping for high-quality products or booking exceptional services, our marketplace is designed to meet all your needs in one place. Explore, connect, and enjoy the best the market has to offer – all tailored for you!",
    image: "/image/BlueblockssProduct.png", // Replace with actual image path
  },
  {
    id: "hospitality",
    title: "Hospitality Management Software",
    description:
      "Elevate guest satisfaction with our Hospitality Software, offering in-room entertainment, information, and management services.",
    image: "/image/hospitality.png", // Replace with actual image path
  },
  {
    id: "education",
    title: "Education",
    description:
      "We provide cutting-edge educational platforms that empower students and educators.",
    image: "/image/education.png", // Replace with actual image path
  },
  {
    id: "nftMarketplace",
    title: "NFT Marketplace",
    description:
      "Our NFT Marketplace solutions allow you to create and manage digital assets with ease.",
    image: "/image/nft.png", // Replace with actual image path
  },
  {
    id: "gamesCasino",
    title: "Games/Casino Apps",
    description:
      "We specialize in developing engaging and immersive gaming experiences, including Casino Apps and online card games.",
    image: "/image/game.png", // Replace with actual image path
  },
];

// Updated Partner Product data
const partnerProduct = {
  title: "Global Health Opinion",
  description:
    "Your gateway to expert second opinions from world-renowned specialists. Connect with experts to make informed medical decisions with confidence.",
  points: [
    "Second Opinion Services",
    "Telemedicine Services",
    "Specialist Services for Cardiology, Oncology, Neurology, and more",
    "Available in 10+ countries",
    "1000+ satisfied patients",
    "200+ expert doctors"
  ],
  image: "/image/global.png", // Replace with actual image path
};

const Products = () => {
  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative bg-gray-100 py-12">
        <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('path_to_hero_image')" }}>
          <div className="bg-primaryBlue rounded-2xl bg-opacity-70 h-full w-full"></div>
        </div>
        <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
          <h1 className="text-5xl font-bold text-white mb-4">Products</h1>
          <h2 className="text-2xl font-semibold text-white">Explore Our Innovative Solutions</h2>
        </div>
      </section>

      {/* Product Categories */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto space-y-12">
          {productData.map((product, index) => (
            <ProductCard
              key={index}
              title={product.title}
              description={product.description}
              image={product.image} // Pass the image path
              productId={product.id} // Pass the productId for dynamic URL
              reverse={index % 2 !== 0} // Alternate layout for each product card
            />
          ))}
        </div>
      </section>

      {/* Partner Product Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto mb-12">
          <h2 className="text-3xl font-bold text-center text-primaryBlue mb-8">Our Partner Product</h2>
          <ServiceCard
            title={partnerProduct.title}
            description={partnerProduct.description}
            points={partnerProduct.points}
            image={partnerProduct.image}
            reverse={false}
          />
        </div>
      </section>
    </Layout>
  );
};

export default Products;
